import { createContext } from "react";

interface AudioContextType {
  currentPlayer: Howl | null;
  setCurrentPlayer: (player: Howl | null) => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

export default AudioContext;
