import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./Routes";
import { useAuth } from "./providers/UserProvider";
import { FlashProvider } from "./providers/FlashProvider";
import { ThemeProvider } from "./providers/ThemeProvider";

export default function App() {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider>
      <div className="min-h-screen bg-white dark:bg-black dark:text-white">
        <FlashProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </FlashProvider>
      </div>
    </ThemeProvider>
  );
}
