import React from "react";
import Signup from "../components/Signup";

export default function SignupPage() {
  return (
    <div className="container mx-auto p-4">
      <Signup />
    </div>
  );
}
