import React, { ReactNode, useState, useContext } from "react";
import AudioContext from "../../contexts/AudioContext";
import { Howl } from "howler";

export function AudioProvider({ children }: { children: ReactNode }) {
  const [currentPlayer, setCurrentPlayer] = useState<Howl | null>(null);

  return <AudioContext.Provider value={{ currentPlayer, setCurrentPlayer }}>{children}</AudioContext.Provider>;
}

export function useAudio() {
  const context = useContext(AudioContext);
  if (context === undefined) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
}
