import React, { useContext, useState, useEffect, ReactNode, useCallback } from "react";
import { apiService } from "../../api/apiService";
import UserContext from "../../contexts/UserContext";
import type { User } from "../../api/apiService";

const REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds
const TOKEN_CHECK_INTERVAL = 1 * 60 * 1000; // Check token every minute

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(true);
  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    setUser(null);
    setSession(false);
  }, []);

  const checkAndRefreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) return;

    try {
      const tokenData = JSON.parse(atob(refreshToken.split(".")[1]));
      const expirationTime = tokenData.exp * 1000;
      const timeUntilExpiry = expirationTime - Date.now();

      if (timeUntilExpiry <= REFRESH_THRESHOLD) {
        const response = await apiService.refreshAccessToken(refreshToken);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
      }
    } catch (err) {
      console.error("Token refresh failed:", err);
      logout();
    }
  }, [logout]);

  useEffect(() => {
    const refreshInterval = setInterval(checkAndRefreshToken, TOKEN_CHECK_INTERVAL);
    return () => clearInterval(refreshInterval);
  }, [checkAndRefreshToken]);

  const checkAuth = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      await checkAndRefreshToken();

      setSession(true);
      const response = await apiService.getAuthenticatedUser();
      setUser(response.data);
    } catch {
      localStorage.removeItem("token");
    } finally {
      setLoading(false);
    }
  }, [checkAndRefreshToken]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const setTokens = (token: string, refreshToken: string, user: User) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refreshToken);
    setUser(user);
    setSession(true);
    setLoading(false);
  };

  return <UserContext.Provider value={{ user, logout, setTokens, session, loading }}>{children}</UserContext.Provider>;
}

export function useAuth() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a UserProvider");
  }
  return context;
}
