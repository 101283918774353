import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "./hooks";

export default function Search() {
  const [query, setQuery] = useState("");
  const { users, error, isLoading } = useSearch(query);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setQuery("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="mx-10 grow" ref={searchRef}>
      <form className="w-full md:w-[400px]" onSubmit={(e) => e.preventDefault()}>
        <div className="relative">
          <input
            type="search"
            placeholder="Search..."
            className="w-full h-10 pl-4 pr-10 input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query && (
            <div className="absolute mt-2 bg-white dark:bg-black max-h-96 overflow-y-auto border border-black w-full md:w-[400px]">
              {isLoading ? (
                <div className="flex justify-center p-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
                </div>
              ) : error ? (
                <div className="p-4 text-red-500 text-center">{error}</div>
              ) : users && users.length > 0 ? (
                users.map((user) => (
                  <Link
                    key={`${user.id}-${user.username}`}
                    to={`/profile/${user.username}`}
                    onClick={() => {
                      setQuery("");
                    }}
                    className="block p-4 hover:bg-gray-50 dark:hover:bg-gray-800 border-b border-gray-200 dark:border-white last:border-0"
                  >
                    <div className="flex items-center space-x-4">
                      <div className="w-8 h-8" style={{ backgroundColor: user.avatar_color }}></div>
                      <div className="flex-1">
                        <div className="">{user.username}</div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="p-4 text-gray-500 text-center">No users found</div>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
