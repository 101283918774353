import { createContext } from "react";
import type { User } from "../api/apiService";

export interface UserContextType {
  user: User | null;
  session: boolean;
  setTokens: (token: string, refreshToken: string, user: User) => void;
  loading: boolean;
  logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export default UserContext;
