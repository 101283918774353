import { useState, useEffect } from "react";
import { apiService, Track } from "../../api/apiService";

export const useMyTracks = () => {
  const [tracks, setTracks] = useState<Track[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchTracks = async () => {
    setLoading(true);
    try {
      const response = await apiService.trackFetchMine();
      setTracks(response.data.tracks);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch tracks"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTracks();
  }, []);

  return { tracks, loading, error, refetch: fetchTracks };
};

export const useFollowingTracks = () => {
  const [tracks, setTracks] = useState<Track[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchTracks = async () => {
    setLoading(true);
    try {
      const response = await apiService.trackFetchFollowing();
      setTracks(response.data.tracks);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch tracks"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTracks();
  }, []);

  return { tracks, loading, error, refetch: fetchTracks };
};

export const useDeleteTrack = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteTrack = async (id: string) => {
    try {
      await apiService.trackDelete(id);
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to delete track"));
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, deleteTrack };
};
