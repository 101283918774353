import React, { useState, useEffect, useRef } from "react";
import { Howl } from "howler";
import { Track } from "../../api/apiService";
import { useAudio } from "../../providers/AudioProvider";
import { useAuth } from "../../providers/UserProvider";
import { useTheme } from "../../providers/ThemeProvider";

interface PlayerProps {
  track?: Track;
  onEnded?: () => void;
}

const Player: React.FC<PlayerProps> = ({ track, onEnded }) => {
  const { user } = useAuth();

  const waveformData = track?.waveform;

  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const howlerRef = useRef<Howl | null>(null);
  const progressInterval = useRef<NodeJS.Timeout>();
  const currentlyPlayingRef = useRef<Howl | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { isDarkMode } = useTheme();

  const { setCurrentPlayer, currentPlayer } = useAudio();

  const canvasLinearGradient = (canvasCtx: CanvasRenderingContext2D, colorStops: Array<[number, string, number?]>) => {
    const height = canvasCtx.canvas.height;

    const gradient = canvasCtx.createLinearGradient(0, 0, 0, height);

    colorStops.forEach(([stop, color, opacity = 1]) => {
      gradient.addColorStop(stop, `rgba(${color},${opacity})`);
    });

    return gradient;
  };

  const memoizeScale = (max: number, data: number[]) => {
    const memo: number[] = [];
    return (v: number) => {
      const s = data[v];
      if (!memo[s]) {
        memo[s] = (max - data[v]) / max;
      }
      return memo[s];
    };
  };

  useEffect(() => {
    if (track) {
      // Cleanup previous howler instance
      if (howlerRef.current) {
        howlerRef.current.unload();
      }

      // Create new howler instance
      howlerRef.current = new Howl({
        src: [track.url],
        html5: true,
        onend: () => {
          setPlaying(false);
          setProgress(0);
          currentlyPlayingRef.current = null;
          setCurrentPlayer(null);
          if (onEnded) onEnded();
        },
      });
    }

    return () => {
      if (howlerRef.current) {
        howlerRef.current.unload();
      }
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [track, onEnded, setCurrentPlayer]);

  useEffect(() => {
    if (!canvasRef.current || !waveformData) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const config = {
      maxVal: 1,
      linePercent: 0.7,
      barWidth: 2,
      gapWidth: 1,
    };

    // Draw base waveform
    const width = canvas.width;
    const height = canvas.height;
    const scaledSamples = memoizeScale(config.maxVal, waveformData);
    const g = config.linePercent * height;
    const v = height - g;
    const increment = config.barWidth + config.gapWidth;
    let w = 0,
      x = 0;

    ctx.clearRect(0, 0, width, height);
    ctx.save();
    ctx.beginPath();

    for (let k = 0; k < width; k += increment) {
      const C = scaledSamples(((k / width) * waveformData.length) | 0);
      const T = (C * g) | 0;
      const S = ((1 - C) * v + g) | 0;

      // Draw bar
      ctx.rect(k, T, config.barWidth, S - T);

      // Draw gap
      const A = Math.max(T, w);
      ctx.fillStyle = "transparent";
      ctx.fillRect(k - config.gapWidth, A, config.gapWidth, Math.min(S, x) - A);

      w = T;
      x = S;
    }

    ctx.fillStyle = canvasLinearGradient(ctx, [
      [0, !isDarkMode ? "50,50,50" : "255,255,255"],
      [0.7, !isDarkMode ? "50,50,50" : "255,255,255"],
      [0.701, !isDarkMode ? "30,30,30" : "229,229,229"],
      [1, !isDarkMode ? "30,30,30" : "229,229,229"],
    ]);
    ctx.fill();
    ctx.clearRect(0, g, width, 1);

    // Draw progress overlay
    ctx.fillStyle = "#3B82F6"; // blue-500
    ctx.globalCompositeOperation = "source-atop";
    ctx.fillRect(0, 0, width * (progress / 100), height);

    ctx.restore();
  }, [waveformData, progress, isDarkMode]);

  const togglePlayPause = () => {
    if (!howlerRef.current) return;

    if (playing) {
      howlerRef.current.pause();
      currentlyPlayingRef.current = null;
      setCurrentPlayer(null);
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    } else {
      // Stop any currently playing instance using the context
      if (currentPlayer && currentPlayer !== howlerRef.current) {
        currentPlayer.pause();
      }

      howlerRef.current.play();
      setCurrentPlayer(howlerRef.current);
      currentlyPlayingRef.current = howlerRef.current;
      progressInterval.current = setInterval(() => {
        if (howlerRef.current) {
          const seek = howlerRef.current.seek() as number;
          const duration = howlerRef.current.duration();
          const p = (seek / duration) * 100;
          setProgress(p ? p : 0);
        }
      }, 100);
    }
    setPlaying(!playing);
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!howlerRef.current) return;

    const newPosition = parseFloat(e.target.value);
    const duration = howlerRef.current.duration();
    const seekTime = (duration * newPosition) / 100;

    howlerRef.current.seek(seekTime);
    setProgress(newPosition);
  };

  const renderWaveform = () => {
    if (!waveformData) return null;

    return (
      <div className="relative w-full h-20 max-w-4xl">
        <canvas
          ref={canvasRef}
          width={800 * window.devicePixelRatio}
          height={100 * window.devicePixelRatio}
          className="top-0 left-0 w-full h-full mx-auto  max-w-4xl"
        />
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleSeek}
          className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
        />
      </div>
    );
  };

  return (
    <div className="">
      <div className="flex items-center space-x-4 mb-4">
        <div className="w-10 h-10" style={{ backgroundColor: user?.avatar_color }} />
        <div className="flex-1 min-w-0">
          <p className=" truncate">{user?.username}</p>
          <p className="text-xl font-light truncate">{track?.name}</p>
        </div>
      </div>
      <div className="flex space-x-4">
        {/* play button */}
        <button onClick={togglePlayPause} className="w-10 h-10 flex-none">
          {playing ? (
            <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg className="w-10 h-10" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>

        {/* Waveform */}
        {renderWaveform()}
      </div>
    </div>
  );
};

export default Player;
