import { useState, useEffect } from "react";
import { apiService, FollowStatus, User } from "../../api/apiService";

export const useSearch = (query: string) => {
  const [users, setUsers] = useState<User[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchUser = async () => {
      if (!query) {
        setUsers(null);
        setError(null);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const response = await apiService.userSearch(query);
        setUsers(response.data);
      } catch {
        setUsers(null);
        setError("User not found");
      } finally {
        setIsLoading(false);
      }
    };

    searchUser();
  }, [query]);

  return { users, error, isLoading };
};

export const useFollowStatus = (user_id: number) => {
  const [followStatus, setFollowStatus] = useState<FollowStatus | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFollowStatus = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await apiService.followStatus(user_id);
        setFollowStatus(response.data.status);
      } catch {
        setError("Error fetching follow status");
      } finally {
        setIsLoading(false);
      }
    };
    fetchFollowStatus();
  }, [user_id]);

  return { followStatus, isLoading, error };
};
