import React, { useState, useContext, useCallback, useEffect } from "react";
import { FlashContext, FlashMessage, FlashType } from "../../contexts/FlashContext";

export function FlashProvider({ children }: { children: React.ReactNode }) {
  const [currentFlash, setCurrentFlash] = useState<FlashMessage | null>(null);

  const hideFlash = useCallback(() => {
    setCurrentFlash(null);
  }, []);

  const showFlash = useCallback((type: FlashType, message: string) => {
    setCurrentFlash({ type, message });
  }, []);

  // Auto-hide flash after 3 seconds
  useEffect(() => {
    if (currentFlash) {
      const timer = setTimeout(() => {
        hideFlash();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [currentFlash, hideFlash]);

  return (
    <FlashContext.Provider value={{ showFlash, currentFlash, hideFlash }}>
      {children}
      {currentFlash && (
        <div
          className={`fixed bottom-4 left-4 p-4 rounded shadow-lg ${
            currentFlash.type === "success"
              ? "bg-green-500"
              : currentFlash.type === "error"
                ? "bg-red-500"
                : "bg-blue-500"
          } text-white`}
        >
          {currentFlash.message}
        </div>
      )}
    </FlashContext.Provider>
  );
}

export const useFlash = () => useContext(FlashContext);
