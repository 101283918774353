import React, { useState } from "react";
import { useAuth } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { apiService } from "../../api/apiService";

interface SignupFormData {
  username: string;
  email: string;
  password: string;
  avatarColor: string;
}

export default function Signup() {
  const navigate = useNavigate();
  const { setTokens } = useAuth();
  const [formData, setFormData] = useState<SignupFormData>({
    username: "",
    email: "",
    password: "",
    avatarColor:
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0"),
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      setLoading(true);

      const response = await apiService.signup(
        formData.username,
        formData.email,
        formData.password,
        formData.avatarColor,
      );

      setTokens(response.data.access_token, response.data.refresh_token, response.data.user);

      navigate("/dashboard");
    } catch (err: unknown) {
      console.log(err);
      if (err instanceof AxiosError) {
        const errorData = err.response?.data;
        setError(errorData?.validation_error?.message || errorData?.message || "An error occurred");
      } else {
        setError("Failed to create account");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-light">Create your account</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className="bg-red-50 p-4">
              <div className="text-sm text-red-700">{error}</div>
            </div>
          )}
          <div className="space-y-6">
            <div>
              <label htmlFor="username" className="text-black dark:text-white block mb-2 font-base">
                Username
              </label>
              <input
                name="username"
                type="text"
                required
                className="input"
                placeholder="username"
                value={formData.username}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="email" className="text-black dark:text-white block mb-2 font-base">
                Email
              </label>
              <input
                name="email"
                type="email"
                required
                className="input"
                placeholder="Email address"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="text-black dark:text-white block mb-2 font-base">
                Password
              </label>
              <input
                name="password"
                type="password"
                required
                className="input"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="avatarColor" className="text-black dark:text-white block mb-2 font-base">
                Avatar color
              </label>
              <input
                name="avatarColor"
                type="color"
                required
                className="input h-10"
                placeholder="Avatar color"
                value={formData.avatarColor}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <button type="submit" disabled={loading} className="button">
              {loading ? "Creating account..." : "Sign up"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
