import React from "react";
import { useMyTracks, useFollowingTracks, useDeleteTrack } from "./hooks";
import Player from "../../components/Player";
// import Wavesurfer from "../../components/Player/wavesurfer";
import { useFlash } from "../../providers/FlashProvider";

export default function Dashboard() {
  const { tracks: myTracks, loading: myTracksLoading, refetch: refetchMyTracks } = useMyTracks();
  const {
    tracks: followingTracks,
    loading: followingTracksLoading,
    refetch: refetchFollowingTracks,
  } = useFollowingTracks();
  const { deleteTrack } = useDeleteTrack();
  const { showFlash } = useFlash();

  console.log(myTracks);

  return (
    <div className="">
      <div className="">
        <h1 className="text-2xl mb-4">My Tracks</h1>

        {myTracksLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {myTracks === null ? (
              <div className="text-gray-500">No tracks uploaded yet</div>
            ) : (
              myTracks.map((track) => {
                return (
                  <div key={track.id} className="py-4">
                    <Player track={track} />

                    <button
                      className="text-red-500"
                      onClick={async () => {
                        try {
                          await deleteTrack(track.id);
                          refetchMyTracks();
                          refetchFollowingTracks();
                          showFlash("success", "Track deleted successfully");
                        } catch {
                          showFlash("error", "Failed to delete track");
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                );
              })
            )}
          </>
        )}
      </div>

      <div className="">
        <h1 className="text-2xl mb-4 mt-10">Following Tracks</h1>

        {followingTracksLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {followingTracks === null ? (
              <div className="text-gray-500">No tracks from people you follow yet</div>
            ) : (
              followingTracks.map((track) => (
                <div key={track.id} className="border-t border-gray-200 py-4">
                  <Player track={track} />
                </div>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
}
