import React from "react";
import { useInboundFollowRequests } from "./hooks";

function Notifications() {
  const { requests, loading, error } = useInboundFollowRequests();

  return (
    <div className="">
      <h1 className="text-2xl mb-6">Notifications</h1>

      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">{error}</div>}

      {loading ? (
        <div className="text-gray-600">Loading notifications...</div>
      ) : requests?.length === 0 ? (
        <div className="text-gray-600">No notifications</div>
      ) : (
        <div className="space-y-4">
          {requests?.map((request) => (
            <div key={request.id} className="bg-white shadow rounded-lg p-4">
              <p className="text-gray-800">New follow request from user #{request.follower_id}</p>
              <p className="text-sm text-gray-500">Received {new Date(request.created_at).toLocaleDateString()}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Notifications;
