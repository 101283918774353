import apiClient from "./client";

export type User = {
  id: number;
  username: string;
  email: string;
  avatar_color: string;
};

type AuthResponse = {
  access_token: string;
  refresh_token: string;
  user: User;
};

export type Track = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  url: string;
  user_id: number;
  waveform: number[];
  duration_seconds: number;
};

export type FollowRequest = {
  id: number;
  follower_id: number;
  followee_id: number;
  status: string;
  created_at: string;
  updated_at: string;
};

export enum FollowStatus {
  NOT_FOLLOWING = "not_following",
  PENDING = "pending",
  APPROVED = "approved",
}

export const apiService = {
  login: (email: string, password: string) => apiClient.post<AuthResponse>("/auth/login", { email, password }),
  signup: (username: string, email: string, password: string, avatar_color: string) =>
    apiClient.post<AuthResponse>("/auth/signup", {
      username,
      email,
      password,
      avatar_color,
    }),
  refreshAccessToken: (refresh_token: string) =>
    apiClient.post<AuthResponse>("/auth/refreshAccessToken", { refresh_token }),

  getAuthenticatedUser: () => apiClient.get<User>("/user/me"),
  getUserProfile: (user_id: number) => apiClient.get<User>(`/user/profile?user_id=${user_id}`),
  userSearch: (query: string) => apiClient.get<User[]>(`/user/search?q=${query}`),

  updateUserAvatarColor: (avatar_color: string) => apiClient.post<User>("/user/update/avatarColor", { avatar_color }),
  updateUserEmail: (email: string) => apiClient.post<User>("/user/update/email", { email }),
  updateUserPassword: (password: string) => apiClient.post<User>("/user/update/password", { password }),
  updateUserUsername: (username: string) => apiClient.post<User>("/user/update/username", { username }),
  // updateUserNotificationPreferences: (preferences: any) => apiClient.post("/user/notificationPreferences/update", { preferences }),

  followRequestCreate: (user_id: number) => apiClient.post("/follow/requests/create", { user_id }),
  followRequestsInbound: () => apiClient.get<{ users: FollowRequest[] }>("/follow/requests/inbound"),
  followRequestsOutbound: () => apiClient.get<{ users: FollowRequest[] }>("/follow/requests/outbound"),
  followStatus: (user_id: number) => apiClient.get<{ status: FollowStatus }>(`/follow/status?user_id=${user_id}`),
  approveFollowRequest: (user_id: number) => apiClient.post("/follow/requests/approve", { user_id }),
  denyFollowRequest: (user_id: number) => apiClient.post("/follow/requests/deny", { user_id }),
  removeFollower: (user_id: number) => apiClient.post("/follow/remove", { user_id }),

  requestResetPassword: (email: string) => apiClient.post("/auth/password/reset/request", { email }),
  resetPassword: (token: string, newPassword: string) => apiClient.post("/auth/password/reset", { token, newPassword }),

  trackCreate: (formData: FormData) =>
    apiClient.post("/tracks/upload", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }),
  trackFetchFollowing: () => apiClient.get<{ tracks: Track[] }>("/tracks/following"),
  trackFetchMine: () => apiClient.get<{ tracks: Track[] }>("/tracks/mine"),
  trackDelete: (track_id: string) => apiClient.post("/tracks/delete", { track_id }),
};
