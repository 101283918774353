import React, { useState } from "react";
import { useAuth } from "../../providers/UserProvider";
import { apiService } from "../../api/apiService";
import { useFlash } from "../../providers/FlashProvider";
import { useTheme } from "../../providers/ThemeProvider";

function SettingsFormField({
  label,
  name,
  type,
  defaultValue,
  onSubmit,
  loading,
}: {
  label: string;
  name: string;
  type: "email" | "password" | "text" | "color";
  defaultValue: string;
  onSubmit: (value: string) => Promise<void>;
  loading: boolean;
}) {
  const [value, setValue] = useState(defaultValue);
  const { showFlash } = useFlash();

  return (
    <form
      onSubmit={(e) => {
        try {
          e.preventDefault();
          onSubmit(value);
          showFlash("success", "Email updated successfully");
        } catch (error) {
          console.log(error);
          showFlash("error", "Failed to update email");
        }
      }}
    >
      <div className="mb-6">
        <label htmlFor={name} className="block text-black dark:text-white mb-2">
          {label}
        </label>
        <div className="flex">
          <input
            id={name}
            type={type}
            className="input h-12"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
          />
          <button type="submit" className={` button flex-1 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}>
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </form>
  );
}
const Settings = () => {
  const { user } = useAuth();
  const { isDarkMode, toggleDarkMode } = useTheme();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="mx-auto max-w-lg mt-10">
      <h1 className="text-2xl mb-6">Settings</h1>
      <SettingsFormField
        label="Email"
        name="email"
        defaultValue={user?.email || ""}
        loading={isLoading}
        type="email"
        onSubmit={async (value) => {
          setIsLoading(true);
          await apiService.updateUserEmail(value);
          setIsLoading(false);
        }}
      />

      <SettingsFormField
        label="Username"
        name="username"
        defaultValue={user?.username || ""}
        loading={isLoading}
        type="text"
        onSubmit={async (value) => {
          setIsLoading(true);
          await apiService.updateUserUsername(value);
          setIsLoading(false);
        }}
      />

      <SettingsFormField
        label="Avatar Color"
        name="avatarColor"
        defaultValue={user?.avatar_color || "#000000"}
        loading={isLoading}
        type="color"
        onSubmit={async (value) => {
          setIsLoading(true);
          await apiService.updateUserAvatarColor(value);
          setIsLoading(false);
        }}
      />

      <div className="flex items-center">
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={isDarkMode}
            onChange={toggleDarkMode}
            className="form-checkbox h-5 w-5 text-black dark:text-white border-black dark:border-white"
          />
          <span className="ml-3 text-lg">Dark Mode</span>
        </label>
      </div>
    </div>
  );
};

export default Settings;
