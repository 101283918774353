import { createContext } from "react";

export type FlashType = "success" | "error" | "info";

export interface FlashMessage {
  type: FlashType;
  message: string;
}

export interface FlashContextType {
  showFlash: (type: FlashType, message: string) => void;
  currentFlash: FlashMessage | null;
  hideFlash: () => void;
}

export const FlashContext = createContext<FlashContextType>({
  showFlash: () => {},
  currentFlash: null,
  hideFlash: () => {},
});
