import React from "react";
import Dropzone, { DropzoneRootProps, DropzoneInputProps } from "react-dropzone";
import { apiService } from "../../api/apiService";
import { useFlash } from "../../providers/FlashProvider";

export default function Uploader() {
  const [isUploading, setIsUploading] = React.useState(false);
  const { showFlash } = useFlash();

  return (
    <Dropzone
      onDrop={(acceptedFiles: File[]) => {
        acceptedFiles.forEach(async (file) => {
          const formdata = new FormData();
          formdata.append("file", file, "[PROXY]");
          formdata.append("name", file.name);
          formdata.append("color", "#0000FF");

          try {
            setIsUploading(true);
            await apiService.trackCreate(formdata);
            showFlash("success", "Track uploaded successfully");
          } catch (error) {
            console.error(error);
            showFlash("error", "Failed to upload track");
          } finally {
            setIsUploading(false);
          }
        });
      }}
    >
      {({
        getRootProps,
        getInputProps,
      }: {
        getRootProps: () => DropzoneRootProps;
        getInputProps: () => DropzoneInputProps & React.InputHTMLAttributes<HTMLInputElement>;
      }) => (
        <section className="border-2 border-dashed text-center cursor-pointer transition-colors">
          <div {...getRootProps()} className="p-10">
            <input {...getInputProps()} />
            {isUploading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white"></div>
                <p className="ml-2">Uploading...</p>
              </div>
            ) : (
              <p>Drag &apos;n&apos; drop some files here, or click to select files</p>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  );
}
