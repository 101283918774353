import React from "react";
import { useParams } from "react-router-dom";

export default function Profile() {
  const { username } = useParams<{ username: string }>();

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold">{username}</h1>

            <div className="text-gray-500">
              <p>Followers: 100</p>
              <p>Following: 100</p>
            </div>

            <div className="text-gray-500">
              <p>Bio: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>

            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Tracks</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {/* Track cards will go here */}
                <div className="border rounded-lg p-4">
                  <h3 className="font-medium">Track Title</h3>
                  <p className="text-gray-500 text-sm">Uploaded on: Jan 1, 2024</p>
                  <div className="mt-2">
                    <button className="text-blue-500 hover:text-blue-600">Play</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
